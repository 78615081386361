import React, { RefAttributes } from 'react';
import { Link, LinkProps } from 'react-router-dom';

type BaseProps = LinkProps & RefAttributes<HTMLAnchorElement>;
type CustomLinkProps = BaseProps & {
  href?: string;
};

function CustomLink({
  children,
  state,
  ...props
}: CustomLinkProps): JSX.Element {
  return (
    <Link
      state={{
        previousUrl: window.location.href,
        ...state,
      }}
      {...props}
    >
      {children}
    </Link>
  );
}

export default CustomLink;
