import React, { memo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { pushGtmEvent } from 'services/gtm/gtmEvents';

import { MOBILE_AND_TABLET } from 'settings/mediaQuery';

import NavigationMenuDesktop from './NavigationMenuDesktop';
import NavigationMenuMobile from './NavigationMenuMobile';

/**
 * Megamenu - Nav bar with global category and its content <br />
 * Contains NavigationMenuDesktop and NavigationMenuMobile
 */
function NavigationMenu({
  show,
  hideMenu,
  visibleSticky,
}: {
  /** Show content */
  show: boolean;
  /** Function call to hide/close menu */
  hideMenu: () => void;
  visibleSticky: boolean;
}): JSX.Element | null {
  const isMobileAndTablet = useMediaQuery({
    query: MOBILE_AND_TABLET,
  });

  const handleGtmEvent = menuEventData => {
    pushGtmEvent({
      event: '_menu_click',
      ...menuEventData,
    });
  };

  return (
    <>
      {isMobileAndTablet && (
        <NavigationMenuMobile
          hideMenu={hideMenu}
          sendEvent={menuEventData => handleGtmEvent(menuEventData)}
          show={show}
        />
      )}
      {!isMobileAndTablet && <NavigationMenuDesktop sticky={visibleSticky} />}
    </>
  );
}

export default memo(NavigationMenu);
