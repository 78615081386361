import React, { memo, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import Image from 'components/Common/Medias/Image';

import {
  CarouselProducts,
  CarouselProductType,
} from 'components/Common/Carousel/CarouselProducts';
import CustomLink from 'components/Common/CustomLink/CustomLink';
import Price from 'components/Common/Price/Price';
import { getUri } from 'services/generic';
import { objectKeysToLowerCase } from 'services/formatter';
import { pushGtmEvent } from 'services/gtm/gtmEvents';
import { getProductData } from 'services/gtm/gtmPageView';
import { formatAcpProduct } from 'services/gtm/gtmUtils';
import { ENV_TWICPICS_URL_MEDIA } from 'settings/env';
import { MOBILE_AND_TABLET_PORTRAIT } from 'settings/mediaQuery';
import { Acp } from 'types/Acp';

import styles from '../SearchBar.module.scss';

/**
 * Display products returned by the search api
 */
function SearchBarProducts(props: {
  /** Products to display */
  products?: Acp['product'];
  /** Function call to close the research suggestion panel */
  closeAcp: () => void;
  /** Number of products to display - Use for CarouselProduct on mobile. */
  productPerPage: number;
}): JSX.Element | null {
  const { products, closeAcp, productPerPage } = props;
  const isMobileAndPortraitTablet = useMediaQuery({
    query: MOBILE_AND_TABLET_PORTRAIT,
  });

  function transformAcpProductToCarouselProducts(
    products?: Acp['product']
  ): CarouselProductType[] {
    if (!products) {
      return [];
    }
    return products?.map(product => {
      const { id, skus } = product;
      const formattedSku = objectKeysToLowerCase(
        skus[0]
      ) as Acp['product'][0]['skus'][0];

      return {
        productId: id,
        visuelFace: formattedSku.imgfacepivot,
        price: formattedSku.prix,
        discountPrice: formattedSku.prixbarre,
        uniquePrice: formattedSku.prixunique,
        shortLabel: formattedSku.shortlabel,
        url: formattedSku.url,
        skuId: formattedSku.skuid,
        stock: 'no',
        modelName: formattedSku.shortlabel,
        metaMarque: formattedSku.metaMarque,
        color: '',
        size: '',
        family: '',
        subfamily: '',
        subuniverse: '',
        universe: '',
        discountPercentage: '',
      };
    });
  }

  useEffect(() => {
    products?.length &&
      pushGtmEvent({
        event: '_product_impression',
        category: 'entrée de recherche',
        products: products?.map((p, i) => {
          const product = p.skus[0];

          return getProductData(
            {
              ...formatAcpProduct(product),
              codeLibelleFamille: product.CodeLibelleFamille,
              codeLibelleSsfamille: product.CodeLibelleSsfamille,
              subuniverse: product.Subuniverse,
              stickerOpeco: 'recherche',
              disponibilite: 'recherche',
            },
            i + 1
          );
        }),
      });
  }, [products]);

  return (
    <>
      {isMobileAndPortraitTablet && (
        <CarouselProducts
          products={transformAcpProductToCarouselProducts(products)}
          closeAcp={closeAcp}
          productPerPage={productPerPage}
          displayDiscount={false}
          productThumbnailClassName={styles.productThumbnail}
          prevArrowClassname={styles.prevArrow}
          nextArrowClassname={styles.nextArrow}
          splideOptions={{
            gap: '15px',
          }}
        />
      )}
      {!isMobileAndPortraitTablet &&
        products?.map((product, i) => {
          const { skus, id: productId } = product;
          if (!skus[0]) return null;
          const {
            url,
            shortlabel,
            imgfacepivot: visuelFace,
            prix: price,
            prixbarre: discountPrice,
            prixunique: uniquePrice,
          } = objectKeysToLowerCase(skus[0]) as Acp['product'][0]['skus'][0];
          return (
            <CustomLink
              to={getUri(url)}
              className={styles.productLink}
              key={productId}
              onClick={() => {
                const p = product.skus[0];
                pushGtmEvent({
                  event: '_product_click',
                  category: 'recherche',
                  element: 'entrée de recherche',
                  products: [
                    getProductData(
                      {
                        ...formatAcpProduct(p),
                        codeLibelleFamille: p.CodeLibelleFamille,
                        codeLibelleSsfamille: p.CodeLibelleSsfamille,
                        subuniverse: p.Subuniverse,
                        stickerOpeco: 'recherche',
                        disponibilite: 'recherche',
                      },
                      i + 1
                    ),
                  ],
                });
                closeAcp();
              }}
            >
              <div className={styles.productContainer}>
                <Image
                  alt={shortlabel}
                  className={styles.productThumbnail}
                  src={`${ENV_TWICPICS_URL_MEDIA}${visuelFace}`}
                />
                <div className={styles.details}>
                  <div className={styles.shortLabel}>{shortlabel}</div>
                  <Price
                    priceContainerClassName={styles.searchPriceContainer}
                    price={price}
                    priceIfDiscount={discountPrice}
                    productPriceClassName={styles.productPrice}
                    newPriceClassName={styles.newPrice}
                    priceIfDiscountClassName={styles.priceIfDiscount}
                    discountPercentageClassName={styles.discount}
                    uniquePrice={uniquePrice}
                  />
                </div>
              </div>
            </CustomLink>
          );
        })}
    </>
  );
}

export default memo(SearchBarProducts);
