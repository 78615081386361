import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { regexUrlProductSheet } from 'services/validation';
import { pushGtmEvent } from 'services/gtm/gtmEvents';
import { ENV_COUNTRY } from 'settings/env';

import styles from './ScrollToTopButton.module.scss';

/**
 * Button present on the bottom right of the page, Allows the user to quickly go to the top of the page
 */
function ScrollToTopButton(): JSX.Element | null {
  const [displayButton, setDisplayButton] = useState(false);
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const handleScroll = () => {
    const rootElement = document.documentElement;
    const scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
    if (rootElement.scrollTop / scrollTotal > 0.1) {
      setDisplayButton(true);
    } else {
      setDisplayButton(false);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (pathname === '/') {
    return null;
  }

  return (
    <button
      className={classnames(styles.root, {
        [styles.productPage]: new RegExp(regexUrlProductSheet).test(pathname),
        [styles.cartPage]: pathname === `/${ENV_COUNTRY}/checkout/cart`,
        [styles.checkoutShippingPage]:
          pathname === `/${ENV_COUNTRY}/checkout/shipping`,
        [styles.checkoutPaymentPage]:
          pathname === `/${ENV_COUNTRY}/checkout/payment`,
        [styles.displayButton]: displayButton,
      })}
      onClick={() => {
        scrollTo(0, 0);
        pushGtmEvent({
          event: '_click_other',
          category: 'clic autre - page liste',
          element: 'retour au début de la page',
        });
      }}
      type="button"
      aria-label={t('LABEL_SCROLL_TO_TOP')}
    >
      <ExpandMoreIcon className={styles.icon} />
    </button>
  );
}

export default ScrollToTopButton;
