import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import useSWR from 'swr';

import FooterBannerCarousel from './FooterBannerCarousel/FooterBannerCarousel';
import DirectOrderLink from 'components/Header/RightNavigationMenu/DirectOrderLink/DirectOrderLink';
import HelpLink from 'components/Header/RightNavigationMenu/HelpLink/HelpLink';
import MyAccountLink from 'components/Header/RightNavigationMenu/MyAccountLink/MyAccountLink';
import CartLink from 'components/Header/RightNavigationMenu/CartLink/CartLink';
import useEnv from 'hooks/useEnv';
import { fetcher } from 'services/fetcher';
import { getFormattedMetaData } from 'services/formatter';
import { getUri } from 'services/generic';
import { oldApi_hasVisibleChildren } from 'services/menu/menu';
import { navigationFormatId } from 'services/string';
import { isReactPage } from 'services/validation';
import { Hierarchy } from 'types/Hierarchy';
import { Menu } from 'types/Menu';
import { MobFooterBanner } from 'types/menu/Menu';

import styles from './NavigationMenuMobile.module.scss';

function NavigationMenuMobile({
  show,
  hideMenu,
  sendEvent,
}: {
  /** Show content */
  show: boolean;
  /** Function call to hide/close menu */
  hideMenu: () => void;
  /** GTM event function */
  sendEvent?: (menuEventData: {
    menu_level_1: string;
    menu_level_2?: string;
    menu_level_3?: string;
    menu_level_4?: string;
  }) => void;
}): JSX.Element | null {
  if (!show) {
    return null;
  }
  const [menuLevel, setMenuLevel] = useState(0);
  const [levelsTitles, setLevelsTitles] = useState<string[]>([]);
  const [historyHierarchy, setHistoryHierarchy] = useState([] as Hierarchy[]);
  const { datetimeApp, urlApiCatalog } = useEnv();
  const { data } = useSWR<Menu>(
    `${urlApiCatalog}/json/Custom/Menu.cxml?${datetimeApp}`,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 0,
    }
  );

  useEffect(() => {
    if (data && !menuLevel) {
      setHistoryHierarchy([data.Zones[0]?.Controls[0]?.Hierarchy]);
    }
  }, [menuLevel, data?.Zones[0].Controls[0].Hierarchy.Id]);

  // TODO: Remove the bellow code when we will use the new API
  const merchBanners = useMemo((): MobFooterBanner[] => {
    const actions = data?.Zones[2]?.Controls[0]?.Actions || [];

    return actions.map(action => {
      return {
        htmlContent: action.HtmlContent,
      };
    });
  }, [data?.Zones[2]?.Controls[0]?.Actions]);

  if (!data || !data.Zones) {
    return null;
  }

  const currentHierarchy = historyHierarchy[menuLevel];

  if (!currentHierarchy) {
    return null;
  }

  const clickLinkHandler = (label: string) => {
    hideMenu();

    if (typeof sendEvent === 'function') {
      sendEvent({
        menu_level_1: levelsTitles[0].toLowerCase(),
        menu_level_2: levelsTitles[1]
          ? levelsTitles[1].toLowerCase()
          : label.toLowerCase(),
        ...(levelsTitles[1] && {
          menu_level_3: levelsTitles[2]
            ? levelsTitles[2].toLowerCase()
            : label.toLowerCase(),
        }),
        ...(levelsTitles[2] && { menu_level_4: label }),
      });
    }
  };

  const htmlLink = (
    label: string,
    url: string,
    className?: string,
    id?: string
  ) => {
    return (
      <a
        className={classNames(styles.button, className)}
        key={url}
        onClick={() => clickLinkHandler(label)}
        href={getUri(url)}
        id={id}
      >
        <span>{label}</span>
        <ArrowForwardIosIcon className={styles.arrow} />
      </a>
    );
  };

  const reactLink = (
    label: string,
    url: string,
    className?: string,
    prefixId?: string
  ) => {
    return (
      <Link
        className={classNames(styles.button, className)}
        key={url}
        onClick={() => clickLinkHandler(label)}
        to={getUri(url)}
        id={`${prefixId}_${navigationFormatId(label)}`}
      >
        <span>{label}</span>
        <ArrowForwardIosIcon className={styles.arrow} />
      </Link>
    );
  };

  const { Label: headLabel, Description: headCustomLabel } = currentHierarchy;
  const headTitle = headCustomLabel ? headCustomLabel : headLabel;

  const getPrefixId = (): string => {
    return historyHierarchy.slice(1).reduce((accumulator, currentValue) => {
      return accumulator + '_' + navigationFormatId(currentValue.Label);
    }, 'oct_megamenu');
  };

  return (
    <div className={styles.navBar}>
      <Drawer
        classes={{
          modal: styles.modalDrawer,
          paper: styles.paperDrawer,
          paperAnchorLeft: styles.paperAnchorLeft,
        }}
        open={show}
        disableEnforceFocus
        disablePortal
      >
        {menuLevel > 0 && (
          <button
            className={styles.backButton}
            type="button"
            onClick={() => {
              setMenuLevel(menuLevel => menuLevel - 1);
              setHistoryHierarchy(historyHierarchy => {
                const newArray = [...historyHierarchy];
                newArray.pop();
                return newArray;
              });
            }}
          >
            <ArrowBackIosIcon className={styles.backArrow} />
            <span>{headTitle}</span>
          </button>
        )}
        {currentHierarchy?.Children?.length > 0 &&
          currentHierarchy.Children.map(category => {
            const {
              MenuMobNoLayer: hideLink,
              CssClass: cssClass,
            } = getFormattedMetaData(category?.Metadata);

            if (hideLink?.Value === 'True') {
              return null;
            }

            const itemHasVisibleChildren = oldApi_hasVisibleChildren(category);
            const { Label: label, Description: customLabel } = category;
            const title = customLabel ? customLabel : label;

            return (
              <>
                {itemHasVisibleChildren && (
                  <button
                    className={classNames(cssClass?.Value, styles.button, {
                      [styles.subItem]: menuLevel > 0,
                    })}
                    onClick={() => {
                      setLevelsTitles([...levelsTitles, label]);
                      setMenuLevel(menuLevel => menuLevel + 1);
                      setHistoryHierarchy(historyHierarchy => [
                        ...historyHierarchy,
                        category,
                      ]);
                    }}
                    key={label}
                    type="button"
                    id={`${getPrefixId()}_${navigationFormatId(label)}`}
                  >
                    <span>{title}</span>
                    <ArrowForwardIosIcon className={styles.arrow} />
                  </button>
                )}
                {isReactPage(category.Url) &&
                  !itemHasVisibleChildren &&
                  reactLink(
                    title,
                    category.Url,
                    classNames(cssClass?.Value, styles.categoryLink, {
                      [styles.subItem]: menuLevel > 0,
                    }),
                    getPrefixId()
                  )}
                {!isReactPage(category.Url) &&
                  !itemHasVisibleChildren &&
                  htmlLink(
                    title,
                    category.Url,
                    classNames(
                      cssClass?.Value,
                      styles.button,
                      styles.categoryLink,
                      {
                        [styles.subItem]: menuLevel > 0,
                      }
                    ),
                    getPrefixId()
                  )}
              </>
            );
          })}

        {merchBanners && <FooterBannerCarousel banners={merchBanners} />}

        <DirectOrderLink
          classes={{ navLink: styles.directOrderLink }}
          onClick={hideMenu}
        />

        <div className={styles.userMobileMenu}>
          <MyAccountLink
            classes={{ navLink: styles.navLink }}
            allowLoggedDropdown={false}
          />

          <HelpLink
            classes={{ navLink: styles.navLink, image: styles.helpImage }}
          />

          <CartLink
            classes={{
              navLink: styles.navLink,
              nbProduct: styles.nbProducts,
              image: styles.basketIcon,
            }}
          />
        </div>
      </Drawer>
    </div>
  );
}

export default NavigationMenuMobile;
