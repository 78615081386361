import React, { useMemo } from 'react';
import classnames from 'classnames';
import Trans from 'components/Common/Translation/Trans';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'services/formatter';

import styles from './Price.module.scss';

/**
 * Product price.
 */
function Price(props: PriceType): JSX.Element {
  const { t } = useTranslation();
  const {
    discountPercentage,
    discountPercentageClassName,
    fromClassName,
    priceIfDiscount,
    price,
    uniquePrice,
    priceContainerClassName,
    productPriceClassName,
    newPriceClassName,
    newPriceContainerClassName,
    normalPriceClassName,
    normalOldPriceClassName,
    tailClassName,
  } = props;

  const hasDiscount = priceIfDiscount && priceIfDiscount !== '0';

  function getFormattedPrice(price?: string) {
    if (!price) {
      return {};
    }
    const priceWithCurrency = formatPrice(price);
    const indexPriceSeparator = priceWithCurrency.search(/\.|\,/);
    return {
      head: priceWithCurrency.slice(0, indexPriceSeparator),
      tail: priceWithCurrency.slice(indexPriceSeparator),
    };
  }

  const formattedPrice = useMemo(() => getFormattedPrice(price), [price]);
  const formattedDiscountPrice = useMemo(
    () => getFormattedPrice(priceIfDiscount),
    [priceIfDiscount]
  );

  return (
    <div className={classnames(styles.priceContainer, priceContainerClassName)}>
      <div
        className={classnames(
          !hasDiscount
            ? classnames(styles.normalPrice, normalPriceClassName)
            : normalOldPriceClassName
        )}
      >
        {uniquePrice !== '1' && (
          <span className={classnames(styles.from, fromClassName)}>
            {t('LABEL_FROM')}
          </span>
        )}
        <span
          className={classnames(styles.productPrice, productPriceClassName, {
            [styles.priceIfDiscount]: hasDiscount,
          })}
        >
          <span className={styles.head}>
            {hasDiscount ? formattedDiscountPrice.head : formattedPrice.head}
          </span>
          <span className={classnames(styles.tail, tailClassName)}>
            {hasDiscount ? formattedDiscountPrice.tail : formattedPrice.tail}
          </span>
        </span>
      </div>
      {hasDiscount && (
        <div
          className={classnames(
            styles.newPriceContainer,
            newPriceContainerClassName
          )}
        >
          <span
            className={classnames(
              styles.discountPercentage,
              discountPercentageClassName
            )}
          >
            <Trans
              i18nKey="LABEL_UP_TO_FORMAT"
              values={{ amount: discountPercentage }}
            />
          </span>
          <span className={classnames(styles.newPrice, newPriceClassName)}>
            <span className={styles.head}>{formattedPrice.head}</span>
            <span className={classnames(styles.tail, tailClassName)}>
              {formattedPrice.tail}
            </span>
          </span>
        </div>
      )}
    </div>
  );
}

type PriceType = {
  /** Discount percentage value */
  discountPercentage?: string;
  /** Overloading css discountPercentage class  */
  discountPercentageClassName?: string;
  /** Overloading css from class */
  fromClassName?: string;
  /** Price value to display when there is a discount */
  priceIfDiscount?: string;
  /** Overloading css priceIfDiscount class */
  priceIfDiscountClassName?: string;
  /** Product price value - Price the customer will pay */
  price: string;
  /** Product unique price value */
  uniquePrice?: string;
  /** Overloading css priceContainer class */
  priceContainerClassName?: string;
  /** Overloading css productPrice class */
  productPriceClassName?: string;
  /** Overloading css newPrice class */
  newPriceClassName?: string;
  /** Overloading css newPriceContainer class */
  newPriceContainerClassName?: string;
  /** Overloading css normalPrice class */
  normalPriceClassName?: string;
  /** Overloading css normalOldPrice class */
  normalOldPriceClassName?: string;
  /** Overloading css tail class */
  tailClassName?: string;
};

export default Price;
