import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';

import HeaderBanner from './HeaderBanner';
import RightNavigationLinks from './RightNavigationMenu/RightNavigationMenu';
import SearchButton from './SearchButton/SearchButton';
import {
  LeftContainer as SeventyLeftContainer,
  RightContainer as SeventyRightContainer,
  SeventyYearsThemeLogoLight,
} from './SeventyYears/SeventyYearsTheme';
import Logo from 'components/Common/Logo/Logo';
import SearchBar, {
  OnCloseType,
  OnCloseTypeKeys,
  SearchBarRefType,
} from 'components/SearchBar/SearchBar';
import { cartContext } from 'contexts/cartContext';
import { LARGE_LAPTOP_AND_DESKTOP, MOBILE, TABLET } from 'settings/mediaQuery';

import MenuBurger from 'assets/img/headerV2/burger.svg';
import PictoClose from 'assets/img/headerV2/close.svg';

import styles from './Header.module.scss';

function Header({
  displayMenu,
  setDisplayMenu,
  isSticky,
}: {
  /** If megaMenu is open */
  displayMenu: boolean;
  /** Function to update current displayMenu value */
  setDisplayMenu: (displayMenu: boolean) => void;
  isSticky: boolean;
}): JSX.Element {
  const { t } = useTranslation();
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);
  const { cart } = useContext(cartContext);
  const searchBarRef = useRef<SearchBarRefType>(null);

  const isMobile = useMediaQuery({ query: MOBILE });
  const isTablet = useMediaQuery({ query: TABLET });
  const isLargeLaptop = useMediaQuery({ query: LARGE_LAPTOP_AND_DESKTOP });
  const nbProducts = cart?.nbProducts || 0;

  useEffect(() => {
    if (showSearchBar) {
      searchBarRef.current?.focusSearchInput();
    }
  }, [showSearchBar]);

  const renderLogo = () => (
    <Logo>
      <SeventyYearsThemeLogoLight classes={{ logo: styles.seventyLogo }} />
    </Logo>
  );

  const renderCloseBtn = (target = 'menu') => (
    <button
      className={styles.closeMenuBtn}
      type="button"
      onClick={() => {
        switch (target) {
          case 'menu':
            setDisplayMenu(false);
            break;
          case 'searchBar':
            setShowSearchBar(false);
            searchBarRef.current?.clearSearchInput();
            break;
          default:
            setDisplayMenu(false);
        }
      }}
      aria-label={t('LABEL_CLOSE')}
    >
      <img src={PictoClose} alt="icon-close-menu" />
    </button>
  );

  const closeSearchBar = (type?: OnCloseTypeKeys) => {
    searchBarRef.current?.clearSearchInput();

    if (
      type === OnCloseType.REDIRECT_CATEGORY ||
      type === OnCloseType.REDIRECT_PRODUCT ||
      type === OnCloseType.TRIGGER_SEARCH
    ) {
      setShowSearchBar(false);
      searchBarRef.current?.closeMerch();
    }
  };

  const openMenuClickHandler = () => {
    setDisplayMenu(true);
    setShowSearchBar(false);
    searchBarRef.current?.closeMerch();
  };

  const showSearchBarBtnHandler = () => {
    setDisplayMenu(false);
    setShowSearchBar(isMobile ? true : showSearchBar);
    if (isTablet) {
      searchBarRef.current?.focusSearchInput();

      return;
    }
  };

  return (
    <div className={styles.headerContainer}>
      <HeaderBanner />
      <div
        className={classNames(styles.header, {
          [styles.fullCart]: nbProducts > 0 && !showSearchBar,
          [styles.dnone]: isSticky,
        })}
      >
        <div className={styles.leftContainer}>
          <SeventyLeftContainer />
          <div className={styles.leftActionBtns}>
            {!displayMenu && (
              <button
                className={styles.openMenuBtn}
                onClick={openMenuClickHandler}
                type="button"
              >
                <img src={MenuBurger} alt="icon-menu" height="40" width="40" />
              </button>
            )}
            {!showSearchBar && (
              <SearchButton
                onClick={showSearchBarBtnHandler}
                classes={{
                  button: classNames(styles.searchBtn, {
                    [styles.menuOpen]: displayMenu,
                  }),
                }}
              />
            )}
          </div>
        </div>
        {renderLogo()}
        <div className={styles.rightContainer}>
          <SeventyRightContainer />
          {(displayMenu && !isLargeLaptop) || (showSearchBar && isMobile) ? (
            renderCloseBtn(displayMenu ? 'menu' : 'searchBar')
          ) : (
            <div className={styles.rightNavigationContainer}>
              <RightNavigationLinks />
            </div>
          )}
        </div>
      </div>
      {(isTablet || showSearchBar) && (
        <div className={classNames(styles.searchBarContainer, styles.visible)}>
          <SearchBar
            ref={searchBarRef}
            sticky={isSticky}
            onClose={closeSearchBar}
            onClickAway={closeSearchBar}
            onRedirect={() => closeSearchBar(OnCloseType.TRIGGER_SEARCH)}
          />
        </div>
      )}
    </div>
  );
}

export default Header;
