import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { getUri } from 'services/generic';
import { pushGtmEvent } from 'services/gtm/gtmEvents';
import { isHomepage } from 'services/validation';

import SeventyLeftLine from 'assets/img/header/70Years/leftLine.svg';
import Logo from 'assets/img/header/70Years/Logo70.svg';
import SeventyRightLine from 'assets/img/header/70Years/rightLine.svg';

import styles from './SeventyYearsTheme.module.scss';

export function LeftContainer(): JSX.Element {
  return (
    <div className={styles.leftSeventy}>
      <SeventyYearsThemeLogo />
      <div className={styles.seventyLineWrapper}>
        <HomepageLink>
          <img
            src={SeventyLeftLine}
            alt={'line'}
            className={styles.seventyLine}
          />
        </HomepageLink>
      </div>
    </div>
  );
}

export function RightContainer(): JSX.Element | null {
  return (
    <div className={styles.rightSeventy}>
      <HomepageLink>
        <img
          src={SeventyRightLine}
          alt={'line'}
          className={styles.seventyLine}
        />
      </HomepageLink>
    </div>
  );
}

export function SeventyYearsThemeLogoLight({
  classes,
}: {
  classes?: { logo?: string };
}): JSX.Element {
  return (
    <img
      src={Logo}
      alt="70 Logo"
      className={classNames(styles.seventyLogo, classes?.logo)}
      height="55"
      width="69"
    />
  );
}

function SeventyYearsThemeLogo(): JSX.Element | null {
  return (
    <div className={styles.seventyLogoWrapper}>
      <HomepageLink>
        <img
          src={Logo}
          alt="70 Logo"
          className={styles.seventyLogo}
          height="81"
          width="101"
        />
      </HomepageLink>
    </div>
  );
}

function HomepageLink({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  return (
    <a
      href={getUri('/')}
      className={classNames(
        isHomepage(pathname) && 'disabled-link',
        styles.homepageLink
      )}
      aria-label={t('LABEL_HOME')}
      onClick={() => {
        pushGtmEvent({
          event: '_header_click',
          category: 'header',
          element: 'logo',
        });
      }}
    >
      {children}
    </a>
  );
}
