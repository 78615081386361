import React from 'react';
import { useTranslation } from 'react-i18next';

import MyAccountMenu from 'components/Account/MyAccountMenu/MyAccountMenu';
import FlashMessageRender from 'components/Common/FlashMessage/FlashMessageRender';

import useGtmPageView from 'hooks/gtm/useGtmPageView';
import { GTM_PAGE_ACCOUNT } from 'settings/gtm/gtmPageView';

import styles from './AccountLayout.module.scss';

const AccountLayout = ({
  children,
}: {
  children?: JSX.Element;
}): JSX.Element => {
  const { t } = useTranslation(['summaryPage']);

  useGtmPageView(GTM_PAGE_ACCOUNT);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <label>{t('LABEL_TITLE_LAYOUT')}</label>
      </div>

      <FlashMessageRender />

      <div className={styles.containerContent}>
        <MyAccountMenu classes={{ menu: { container: styles.menu } }} />
        <div className={styles.children}>{children}</div>
      </div>
    </div>
  );
};

export default AccountLayout;
