import { useContext, useEffect, useMemo, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';
import { userContext } from 'contexts/userContext';
import { cartContext } from 'contexts/cartContext';
import { useCustomer } from 'hooks/useCustomer';
import useEnv from 'hooks/useEnv';
import { getDataLayer } from 'services/gtm/gtmPageView';
import { formatCartProduct } from 'services/gtm/gtmUtils';
import { GTM_CART_PRODUCTS_PAGE } from 'settings/gtm/gtmPageView';
import { GtmDataType, GtmOrderDataType } from 'types/gtm/GtmPageView';

export default function useGtmPageView(
  pageType: string,
  data: GtmDataType = null
): void {
  const { pathname, state } = useLocation();
  const { cart, isLoaded: cartLoaded } = useContext(cartContext);
  const { retarg, gtmUserCookie } = useContext(userContext);
  const { customer, isLoaded: customerLoaded } = useCustomer();
  const { country, env } = useEnv();
  const [dataSent, setDataSent] = useState(false);
  const previousUrl = state?.previousUrl;
  const gtmCart = (data as GtmOrderDataType)?.cart ?? cart;

  const cartProducts =
    GTM_CART_PRODUCTS_PAGE.includes(pageType) &&
    useMemo(
      () =>
        gtmCart?.products.map(product => {
          return formatCartProduct(product);
        }),
      [gtmCart?.products]
    );
  const dataReady = (): boolean => {
    if (data === null) {
      return true;
    }

    const { isLoaded } = { ...data };

    return !!isLoaded && !Object.values(data).includes(undefined);
  };

  useEffect(() => {
    try {
      if (
        dataSent ||
        !cartLoaded ||
        retarg === undefined ||
        !customerLoaded ||
        !dataReady()
      ) {
        return;
      }

      const dataLayer = getDataLayer({
        cart: gtmCart,
        country,
        customer,
        env,
        pageType,
        retarg,
        gtmUserCookie,
        previousUrl,
        ...(cartProducts && { products: cartProducts }),
        ...data,
      });

      TagManager.dataLayer({
        dataLayer,
      });
      setDataSent(true);
    } catch {
      console.debug('error');
    }
  }, [cart, retarg, customer, data, dataSent]);

  //[cart, retarg, customer, data, dataSent]

  useEffect(() => {
    if (dataSent) {
      setDataSent(false);
    }
  }, [pathname]);
}
