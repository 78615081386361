import React from 'react';
import classNames from 'classnames';

import MenuItem, { MenuItemProps } from './MenuItem/MenuItem';
import useMenu from 'hooks/Menu/useMenu';

import styles from './Menu.module.scss';

type MenuProps = {
  classes?: {
    menuContainer?: string;
    menuItem?: MenuItemProps['classes'];
  };
};

function Menu({ classes }: MenuProps): JSX.Element {
  const { hierarchy, dynamicZones, serviceBanner } = useMenu();
  const menuItemClasses = { ...classes?.menuItem };

  menuItemClasses.container = classNames(
    menuItemClasses?.container,
    styles.menuItem
  );

  return (
    <ul className={classNames(styles.container, classes?.menuContainer)}>
      {hierarchy?.map(item => {
        const menuNoLayer = item.metadata.menuNoLayer || false;
        if (menuNoLayer) {
          return false;
        }

        return (
          <MenuItem
            key={item.id}
            item={item}
            banners={dynamicZones && dynamicZones[item.id]}
            serviceBanner={serviceBanner}
            classes={menuItemClasses}
          />
        );
      })}
    </ul>
  );
}

export default Menu;
