/**
 * Helper to get product information
 */
import {
  ProductType,
  ProductVariantType,
  SelectedProductAttributes,
} from 'types/ProductPageV2/Products';

export const SPECIFIC_BRANDS = ['Laguiole', 'MESSEGUE', 'Tradition des Vosges'];
export const DEFAULT_AFIBEL_SEPARATOR_CHARACTER = '¤';

export function getColor(productAttributes: ProductAttributes): string {
  return productAttributes.couleur.Value;
}

export function getName(productAttributes: ProductAttributes): string {
  return productAttributes.libelle.Value;
}

export function getModelName(productAttributes: ProductAttributes): string {
  return productAttributes.nomModele.Value.toUpperCase();
}

export function getFullName(productAttributes: ProductAttributes): string {
  return `${getName(productAttributes)} ${getModelName(
    productAttributes
  )} ${getColor(productAttributes)}`;
}

export function getDescription(productAttributes: ProductAttributes): string {
  return productAttributes.description.Value;
}

export function getCatalogSku(productAttributes: ProductAttributes): string {
  return productAttributes.catalogueSKU.Value;
}

export function getRealSku(productAttributes: ProductAttributes): string {
  return productAttributes.SKUReelle.Value;
}

export function getEan(productAttributes: ProductAttributes): string {
  return productAttributes.EAN?.Value || '';
}

export function getUrl(
  product: ProductType | ProductVariantType,
  updateCallback?: (url: string) => string
): string {
  return updateCallback ? updateCallback(product.Url) : product.Url;
}

export function getFinalPrice(productAttributes: ProductAttributes): number {
  return +productAttributes.prix.Value;
}

export function getProductAvailabilityStatus(
  productAttributes: ProductAttributes
): string {
  return productAttributes.disponibilite.Value;
}

export function getBrandName(
  productAttributes: ProductAttributes,
  defaultValue = 'Afibel'
): string {
  return productAttributes.metaMarque?.Value || defaultValue;
}

export function getStandardImages(
  productAttributes: ProductAttributes,
  updateCallback?: (path: string) => string
): Array<string> {
  const standardOtherImages = getSplitImages(
    productAttributes.imgAutresSlim.Value || ''
  );

  if (standardOtherImages.length > 0) {
    return standardOtherImages.map(path =>
      updateCallback ? updateCallback(path) : path
    );
  }

  const standardMainImage = productAttributes.imgFaceSlim?.Value;

  return (standardMainImage ? [standardMainImage] : []).map(path =>
    updateCallback ? updateCallback(path) : path
  );
}

export function getStandardVideo(
  productAttributes: ProductAttributes,
  updateCallback?: (path: string) => string
): string | null {
  const result = productAttributes.vidSlim?.Value || null;

  return result && updateCallback ? updateCallback(result) : result;
}

export function getStandardVideoVignette(
  productAttributes: ProductAttributes,
  updateCallback?: (path: string) => string
): string | null {
  const result = productAttributes.imgVignetteSlim?.Value || null;

  return result && updateCallback ? updateCallback(result) : result;
}

export function getCurvyImages(
  productAttributes: ProductAttributes,
  updateCallback?: (path: string) => string
): Array<string> {
  const curvyOtherImages = getSplitImages(
    productAttributes.imgAutresCurvy?.Value || ''
  );

  if (curvyOtherImages.length > 0) {
    return curvyOtherImages.map(path =>
      updateCallback ? updateCallback(path) : path
    );
  }

  const curvyMainImage = productAttributes.imgFaceCurvy?.Value;

  return (curvyMainImage ? [curvyMainImage] : []).map(path =>
    updateCallback ? updateCallback(path) : path
  );
}

export function getCurvyVideo(
  productAttributes: ProductAttributes,
  updateCallback?: (path: string) => string
): string | null {
  const result = productAttributes.vidCurvy?.Value || null;

  return result && updateCallback ? updateCallback(result) : result;
}

export function getCurvyVideoVignette(
  productAttributes: ProductAttributes,
  updateCallback?: (path: string) => string
): string | null {
  const result = productAttributes.imgVignetteCurvy?.Value || null;

  return result && updateCallback ? updateCallback(result) : result;
}

export function getIdCustColor(productAttributes: ProductAttributes): string {
  return productAttributes.idCustColor.Value;
}

export function hasSpecificBrands(
  productAttributes: ProductAttributes
): boolean {
  return SPECIFIC_BRANDS.includes(productAttributes.metaMarque?.Value);
}

/**
 * Split image string by separator
 *
 * @param data
 * @param separator
 *
 * @return string[]
 */
function getSplitImages(
  data: string,
  separator = DEFAULT_AFIBEL_SEPARATOR_CHARACTER
): Array<string> {
  return data.split(separator).filter(Boolean);
}

type ProductAttributes = SelectedProductAttributes;
