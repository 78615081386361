import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

import { getRoutesUri, getUri } from 'services/generic';
import { MOBILE_AND_TABLET_PORTRAIT } from 'settings/mediaQuery';

import styles from './Ariane.module.scss';

const Ariane = ({
  arianeActive,
  isLoggedLink,
  thirdStepArianeTitle,
  thirdStepArianeLinkWhenFourthExist,
  fourthStepArianeTitle,
}: {
  arianeActive?: boolean;
  isLoggedLink?: boolean;
  thirdStepArianeTitle?: string;
  thirdStepArianeLinkWhenFourthExist?: string;
  fourthStepArianeTitle?: string;
}): JSX.Element => {
  const { t } = useTranslation();
  const isMobileAndPortraitTablet = useMediaQuery({
    query: MOBILE_AND_TABLET_PORTRAIT,
  });

  if (isMobileAndPortraitTablet || !arianeActive) {
    return <></>;
  }

  return (
    <div
      className={styles.ariane}
      style={
        !isLoggedLink
          ? { maxWidth: 1084, width: 'auto', padding: 0 }
          : { justifyContent: 'left' }
      }
    >
      <label>
        <Link to={getUri('')}>{t('LABEL_HOME')}</Link>
      </label>
      <span>|</span>
      {isLoggedLink ? (
        <label className={thirdStepArianeTitle && styles.secondStepNotActive}>
          <Link to={getRoutesUri('customerAccountOrderHistory') as string}>
            {t('LABEL_MY_ACCOUNT')}
          </Link>
        </label>
      ) : (
        <label className={thirdStepArianeTitle && styles.secondStepNotActive}>
          <Link to={getRoutesUri('customerLogin') as string}>
            <label
              className={
                !thirdStepArianeTitle ? styles.identificationAriane : ''
              }
            >
              {t('LABEL_IDENTIFICATION')}
            </label>
          </Link>
        </label>
      )}
      {thirdStepArianeTitle && (
        <>
          <span>|</span>
          <label
            className={!fourthStepArianeTitle ? styles.thirdStepActive : ''}
          >
            {thirdStepArianeLinkWhenFourthExist ? (
              <Link to={thirdStepArianeLinkWhenFourthExist}>
                {thirdStepArianeTitle}
              </Link>
            ) : (
              thirdStepArianeTitle
            )}
          </label>
        </>
      )}
      {fourthStepArianeTitle && (
        <>
          <span>|</span>
          <label className={styles.fourthStepActive}>
            {fourthStepArianeTitle}
          </label>
        </>
      )}
    </div>
  );
};

export default Ariane;
