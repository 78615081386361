import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import useSWR from 'swr';

import useEnv from 'hooks/useEnv';
import { fetcher } from 'services/fetcher';
import { replaceByLinkEnv } from 'services/formatter';
import { Menu } from 'types/Menu';

import styles from './MerchBanners.module.scss';

type MerchBannersProps = {
  portalElement?: HTMLElement;
};

function MerchBanners({ portalElement }: MerchBannersProps): JSX.Element {
  const { datetimeApp, urlApiCatalog } = useEnv();
  const { data } = useSWR<Menu>(
    `${urlApiCatalog}/json/Custom/Site.cxml?${datetimeApp}`,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 0,
    }
  );

  useEffect(() => {
    document.body.classList.add(styles.bodyMerchOpen);

    return () => {
      document.body.classList.remove(styles.bodyMerchOpen);
    };
  }, []);

  const merchBanner1 = data?.Zones[3]?.Controls[0]?.Actions[0]?.HtmlContent;
  const merchBanner2 = data?.Zones[4]?.Controls[0]?.Actions[0]?.HtmlContent;
  const merchBanner3 = data?.Zones[5]?.Controls[0]?.Actions[0]?.HtmlContent;
  const el = (
    <div className={styles.merchBanners}>
      {merchBanner1 && (
        <div
          className={styles.bannerItem}
          dangerouslySetInnerHTML={{
            __html: replaceByLinkEnv(merchBanner1),
          }}
        />
      )}
      {merchBanner2 && (
        <div
          className={styles.bannerItem}
          dangerouslySetInnerHTML={{
            __html: replaceByLinkEnv(merchBanner2),
          }}
        />
      )}
      {merchBanner3 && (
        <div
          className={styles.bannerItem}
          dangerouslySetInnerHTML={{
            __html: replaceByLinkEnv(merchBanner3),
          }}
        />
      )}
    </div>
  );

  return portalElement ? ReactDOM.createPortal(el, portalElement) : el;
}

export default MerchBanners;
