import { sha256 } from 'js-sha256';
import moment from 'moment';
import {
  filterData,
  decimalRound,
  getHTPrice,
  getPageCategories,
  getPageFilters,
  getProductColorModel,
  getProductModel,
  getSearchStatus,
  isDirectOrder,
  getProductAccess,
} from 'services/gtm/gtmUtils';
import {
  GTM_CATEGORY_ORDER,
  GTM_CATEGORY_PAGE,
  GTM_CATEGORY_PRODUCTS,
  GTM_CATEGORY_SEARCH,
  GTM_CATEGORY_USER,
  GTM_ENV_CURRENCY,
  GTM_ENV_VERSION,
  GTM_PAGE_VIEW,
} from 'settings/gtm/gtmPageView';
import { GTM_DATA_MAPPING } from 'settings/gtm/gtmPageViewMapping';
import { Acp } from 'types/Acp';
import { BreadCrumb } from 'types/Controls/BreadCrumb';
import { Cart } from 'types/Cart';
import { AppliedFilter } from 'types/Controls/NextFilters';
import { SortingGroup } from 'types/Controls/Sorting';
import { CustomerType } from 'types/Customer';
import { GtmUserCookieType } from 'types/GtmUserCookie';
import {
  FormattedProductType,
  GtmEnvironmentType,
  GtmOrderType,
  GtmPageType,
  GtmProductsType,
  GtmProductType,
  GtmSearchType,
  GtmUserType,
} from 'types/gtm/GtmPageView';

export function getDataLayer({
  acp,
  appliedFilters,
  breadCrumb,
  cart,
  country,
  customer,
  env,
  error,
  pageType,
  products,
  retarg,
  gtmUserCookie,
  previousUrl,
  searchTerm,
  currentSortingGroup,
  orderId,
}: {
  acp?: Acp;
  appliedFilters?: AppliedFilter[];
  breadCrumb?: BreadCrumb;
  cart: Cart | undefined;
  country: string;
  customer: CustomerType | undefined;
  env: string;
  error?: string;
  pageType: string;
  products?: FormattedProductType[];
  retarg: string | undefined;
  gtmUserCookie: GtmUserCookieType | undefined;
  previousUrl: string;
  searchTerm?: string;
  currentSortingGroup?: SortingGroup;
  orderId: string | undefined;
}): {
  [key: string]: unknown;
} {
  const mapping = GTM_DATA_MAPPING[pageType];
  const categories = Object.keys(mapping);
  const productsData = getProductsData(products, previousUrl);

  const data = {
    event: GTM_PAGE_VIEW,
    ...getEnvironmentData(country, env),
    ...(categories.includes(GTM_CATEGORY_PAGE) &&
      getPageData(
        pageType,
        breadCrumb,
        error,
        appliedFilters,
        currentSortingGroup
      )),
    ...(categories.includes(GTM_CATEGORY_USER) &&
      getUserData(retarg, customer, gtmUserCookie)),
    ...(categories.includes(GTM_CATEGORY_PRODUCTS) && productsData),
    ...(categories.includes(GTM_CATEGORY_ORDER) && {
      ...getOrderData(cart, orderId),
      order_add_to_cart_type: productsData
        ? getOrderAddToCartType(productsData)
        : undefined,
    }),
    ...(categories.includes(GTM_CATEGORY_SEARCH) &&
      getSearchData(acp, searchTerm)),
  };

  return filterData(pageType, data);
}

function getEnvironmentData(country: string, env: string): GtmEnvironmentType {
  const env_internal_traffic =
    navigator.userAgent.includes('ip-label') ||
    navigator.userAgent.includes('Prerender');

  return {
    env_country: country,
    env_currency: GTM_ENV_CURRENCY,
    env_language: window.navigator.language,
    env_work: env,
    env_timestamp: Date.now(),
    env_version: GTM_ENV_VERSION,
    env_internal_traffic,
    env_date: moment(Date.now()).format('DD/MM/YYYY'),
  };
}

function getPageData(
  pageType: string,
  breadCrumb?: BreadCrumb,
  error?: string,
  appliedFilters?: AppliedFilter[],
  currentSortingGroup?: SortingGroup
): GtmPageType {
  const categories = breadCrumb && getPageCategories(breadCrumb);

  return {
    page_category_1: categories && categories[0],
    page_category_2: categories && categories[1],
    page_category_3: categories && categories[2],
    page_error: error,
    page_type: pageType,
    page_title: document.title,
    page_filters: appliedFilters?.length
      ? getPageFilters(appliedFilters)
      : undefined,
    page_sort: currentSortingGroup?.Label.toLowerCase(),
    page_name: document.location.pathname,
    page_url: location.href,
  };
}

function getUserData(
  retarg: string | undefined,
  customer: CustomerType | undefined,
  gtmUserCookie: GtmUserCookieType | undefined
): GtmUserType {
  return {
    user_id: retarg,
    user_email: customer?.email && sha256(customer.email),
    user_type: gtmUserCookie?.user_status,
    user_login_status: customer?.isLoggedIn,
    ...(customer?.isLoggedIn && {
      user_login_type: localStorage.getItem('user_login_type') || undefined,
    }),
    user_order_number: gtmUserCookie?.user_nb_command,
  };
}

export function getProductsData(
  products?: FormattedProductType[],
  previousUrl?: string | undefined
): GtmProductsType {
  const gtmProducts: GtmProductType[] = [];
  const access = getProductAccess(previousUrl);

  products &&
    products.map((product, line) => {
      gtmProducts.push(getProductData(product, line + 1, access));
    });

  return { products: gtmProducts };
}

export function getProductData(
  product: FormattedProductType,
  listPosition?: number,
  access?: string | undefined
): GtmProductType {
  const {
    codeLibelleFamille,
    codeLibelleSsfamille,
    couleur,
    disponibilite,
    libelle,
    libelleTaille,
    metaMarque,
    prix,
    prixBarre,
    quantity,
    SKUReelle,
    stickerOpeco,
    subuniverse,
  } = product;

  const price = prix !== '0' && prix !== '' ? prix : undefined;
  const crossedOutPrice =
    prixBarre !== '0' && prixBarre !== '' ? prixBarre : undefined;

  const add_to_cart_types = JSON.parse(
    localStorage.getItem('add_to_cart_types') ?? '[]'
  );

  const add_to_cart_type = add_to_cart_types.find(p => p.sku === SKUReelle)
    ?.add_to_cart_type;
  const isDirectOrderProduct =
    add_to_cart_type && isDirectOrder(add_to_cart_type);

  const price_tf_original =
    price?.length && getHTPrice(parseFloat(crossedOutPrice ?? price));
  const price_ati_original =
    price?.length && parseFloat(crossedOutPrice ?? price);
  const price_tf_after_discount =
    price?.length && getHTPrice(parseFloat(price));
  const price_ati_after_discount = price?.length && parseFloat(price);
  const discount_ati =
    price_ati_original &&
    price_ati_after_discount &&
    decimalRound(price_ati_original - price_ati_after_discount);
  const discount_tf =
    price_tf_original &&
    price_tf_after_discount &&
    decimalRound(price_tf_original - price_tf_after_discount);

  return {
    r: SKUReelle && getProductModel(SKUReelle),
    rc: SKUReelle && getProductColorModel(SKUReelle),
    rct: SKUReelle,
    ...(libelle && { name: libelle.toLowerCase() }),
    ...(isDirectOrderProduct && { name: 'commande direct' }),
    brand: metaMarque?.toLowerCase() || 'afibel',
    variant: SKUReelle,
    ...(couleur && { color: couleur.toLowerCase() }),
    ...(libelleTaille && { size: libelleTaille.toLowerCase() }),
    ...(codeLibelleFamille && { category_1: codeLibelleFamille.toLowerCase() }),
    ...(isDirectOrderProduct && { category_1: 'commande direct' }),
    ...(codeLibelleSsfamille && {
      category_2: codeLibelleSsfamille.toLowerCase(),
    }),
    ...(subuniverse && { category_3: subuniverse.toLowerCase() }),
    ...(listPosition && { list_position: listPosition }),
    price_tf_original,
    price_ati_original,
    price_tf_after_discount,
    price_ati_after_discount,
    discount_ati,
    discount_tf,
    ...(quantity && { quantity: parseInt(quantity) }),
    ...(add_to_cart_type && { add_to_cart_type: add_to_cart_type }),
    coupon: localStorage.getItem('privilegeCode') || '',
    ...(stickerOpeco?.split('¤')[0] && {
      sticker: stickerOpeco.split('¤')[0].toLowerCase(),
    }),
    ...(isDirectOrderProduct && {
      sticker: 'commande direct',
    }),
    access: access,
    stock: disponibilite?.toLocaleLowerCase(),
  };
}

function getOrderData(cart?: Cart, orderId?: string | undefined): GtmOrderType {
  const order_discount_amount_ati = cart?.products
    ?.map(p =>
      p.discountType === 'M' ? decimalRound(p.crossedOutPrice - p.price) : 0
    )
    .reduce((a, b) => a + b, 0);
  const order_discount_amount_tf =
    order_discount_amount_ati && getHTPrice(order_discount_amount_ati);

  const order_amount_ati = cart?.totalPrice;
  const order_amount_tf = cart && getHTPrice(cart.totalPrice);

  return {
    order_cart_id: cart?.cartId,
    order_timestamp: Date.now(),
    order_total_item_quantity: cart?.nbProducts,
    order_amount_ati,
    order_amount_tf,
    order_shipping_value: cart?.shippingFees,
    order_tax_value: decimalRound(order_amount_ati - order_amount_tf),
    order_id: orderId ?? cart?.cartId,
    order_unique_items: cart?.products.length,
    order_payment_method: cart?.paymentMethod,
    order_shipping_method: cart?.deliveryMethod?.delivery,
    order_coupon_code: cart?.currentPrivilegeCode,
    order_discount_amount_tf: order_discount_amount_tf,
    order_discount_amount_ati: order_discount_amount_ati,
    order_shipping_country: cart?.deliveryMethod?.address?.country,
  };
}

function getSearchData(acp?: Acp, searchTerm?: string): GtmSearchType {
  return {
    search_term: searchTerm,
    search_status: acp
      ? getSearchStatus(acp.totalHits, acp.product)
      : undefined,
    search_result_number: acp?.totalHits,
  };
}

function getOrderAddToCartType(productsData: GtmProductsType): string {
  let order_add_to_cart_type = '';
  const add_to_cart_types: string[] = [];

  productsData?.products.forEach(pr => {
    const add_to_cart_type = pr.add_to_cart_type || '';
    !add_to_cart_types.includes(add_to_cart_type) &&
      add_to_cart_types.push(add_to_cart_type);
  });

  if (add_to_cart_types.length > 1) order_add_to_cart_type = 'mixte';
  else if (add_to_cart_types.length === 1) {
    if (add_to_cart_types[0].includes('fiche produit'))
      order_add_to_cart_type = 'standard uniquement';
    if (add_to_cart_types[0] === 'commande direct')
      order_add_to_cart_type = 'commande direct uniquement';
  }

  return order_add_to_cart_type;
}
