import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import HeaderLayout from '../layout/HeaderLayout';
import ScrollToTopButton from 'components/Common/ScrollToTopButton/ScrollToTopButton';
import useEnv from 'hooks/useEnv';
import Ariane from 'components/Ariane/Ariane';

function MainLayout({
  children,
  emptyLayout = false,
  recaptcha = false,
  arianeActive,
  isLoggedLink,
  thirdStepArianeTitle,
  thirdStepArianeLinkWhenFourthExist,
  fourthStepArianeTitle,
}: {
  children:
    | React.LazyExoticComponent<() => JSX.Element | null>
    | JSX.Element
    | null;
  emptyLayout?: boolean;
  recaptcha?: boolean;
  arianeActive?: boolean;
  isLoggedLink?: boolean;
  thirdStepArianeTitle?: string;
  thirdStepArianeLinkWhenFourthExist?: string;
  fourthStepArianeTitle?: string;
}): JSX.Element {
  const AfibelFooter = React.lazy(
    () => import('../components/AfibelFooter/AfibelFooter')
  );
  const { recaptchaId } = useEnv();

  function render(): JSX.Element {
    return (
      <>
        <div>
          {!emptyLayout && <HeaderLayout />}
          <Ariane
            arianeActive={arianeActive}
            isLoggedLink={isLoggedLink}
            thirdStepArianeTitle={thirdStepArianeTitle}
            thirdStepArianeLinkWhenFourthExist={
              thirdStepArianeLinkWhenFourthExist
            }
            fourthStepArianeTitle={fourthStepArianeTitle}
          />
          <>{children}</>
          <ScrollToTopButton />
        </div>
        <AfibelFooter />
      </>
    );
  }

  return (
    <>
      {recaptcha ? (
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaId}>
          {render()}
        </GoogleReCaptchaProvider>
      ) : (
        render()
      )}
    </>
  );
}

export default MainLayout;
